//react
import { useEffect, useRef } from "react";
//css library
import styled from "@emotion/styled";
//mui components
import MuiTextField from "@mui/material/TextField";
import { spacing } from "@mui/system";
const TextFieldSpacing = styled(MuiTextField)(spacing);
const TextField = styled(TextFieldSpacing)``;
//sqlitecloud components
import InputWithCustomLabel from "@generic-components/Form/InputWithCustomLabel";

const ModalInput = (props) => {
  const inputRef = useRef(null);
  useEffect(() => {
    if (props.autoFocus) {
      setTimeout(() => {
        console.log(inputRef);
        inputRef.current.focus();
      }, 0);
    }
  }, [props.autoFocus]);
  return (
    <InputWithCustomLabel {...props}>
      <TextField
        type={props.type}
        fullWidth
        className="subvariant-modal-form"
        error={props.error}
        helperText={props.helperText}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        inputRef={inputRef}
        InputProps={props.InputProps}
      />
      {props.children}
    </InputWithCustomLabel>
  );
};

export default ModalInput;
